<template>
  <div class="page pagezclist">
    <el-drawer size="335px" :modal="false" :append-to-body="false" :modal-append-to-body="false" :visible.sync="drawer"
               :with-header="false">
      <div class="drawer_box">
        <el-row style="margin-bottom: 10px;" class="flexEnd flex_1 ">
          <el-button size="small" type="primary" plain icon="el-icon-search" @click="onSearch">查询
          </el-button>
          <el-button size="small" type="primary" plain @click="onReset">重置
          </el-button>
        </el-row>
        <el-divider style="width: 100%"></el-divider>
        <el-form :model="queryForm" ref="queryForm" label-width="10px">
          <el-row>分字段搜索</el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="">
                <el-input v-model="queryForm.title" size="mini" placeholder="资产名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="">
                <el-select v-model="queryForm.zc_gztype" size="mini" :disabled="disabled" placeholder="购置方式">
                  <el-option label="外购" value="外购"></el-option>
                  <el-option label="租赁" value="租赁"></el-option>
                  <el-option label="借用" value="借用"></el-option>
                  <el-option label="自行建造" value="自行建造"></el-option>
                  <el-option label="其他方式" value="其他方式"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="">
                <el-input v-model="queryForm.pinpai" size="mini" placeholder="品牌"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="">
                <el-input v-model="queryForm.xinghao" size="mini" placeholder="型号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="">
                <el-input v-model="queryForm.jldw" size="mini" placeholder="计量单位"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="labelCss">
              <el-form-item label="价值区间:" label-width="80px">
                <div class="flexStart">
                  <el-input v-model="queryForm.jine1" size="mini" placeholder="最小值"></el-input>
                  <span style="margin: 0 10px;">-</span>
                  <el-input v-model="queryForm.jine2" size="mini" placeholder="最大值"></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>筛选</el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="">
                <el-cascader ref="myCascader1" v-model="queryForm.zc_typeid" :options="typeList" size="mini"
                             :props="optionProps1"
                             :show-all-levels="true" filterable placeholder="资产分类"
                             @change="handleCascaderChange1"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="">
                <el-cascader ref="myCascader" v-model="queryForm.zc_storeid" :options="areaList" size="mini"
                             :props="optionProps"
                             :show-all-levels="true" filterable placeholder="所在位置"
                             @change="handleCascaderChange"></el-cascader>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="">
                <el-date-picker v-model="queryForm.zc_date" type="date" size="mini" value-format="yyyy-MM-dd"
                                placeholder="购置/借用日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-drawer>
    <div id="printDiv" v-if="showPrinter">ahahahahahha</div>
    <el-row style="margin-bottom: 10px;" class="flexBetween flex_1 ">
      <div class="flex_1">
        <el-button size="small" type="primary" plain icon="el-icon-plus" @click="add">新增</el-button>
        <el-dropdown v-if="false" split-button trigger="click" type="primary" plain size="small" class="cus_dropdown"
                     style="margin-left: 10px;"><i
            class="el-icon-printer" style="margin-right: 5px;"></i>打印
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item divided>
              <div @click="print_ruku">打印入库单</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button style="margin-left: 10px;" size="small" type="danger" plain icon="el-icon-delete" @click="delSlct">删除
        </el-button>
        <el-button icon="el-icon-download" type="text" size="small" style="margin-left: 10px;">
          <a href="./downloadFiles/实训耗材导入模板1207.xls" target="_blank"
             style="text-decoration: none; color: inherit;font-size: 14px;">下载模板</a>
        </el-button>
        <el-link type="primary" @click="uploadXls" style="margin-left: 10px;" target="_blank">导入模板</el-link>
        <div style="display: none;">
          <el-upload action="" :multiple="true" :http-request="uploadOss" :on-success="uploadAttch"
                     :show-file-list="false"
                     name="image">

            <el-button size="mini" id="addXlsbtn">+</el-button>
          </el-upload>
        </div>
      </div>
      <div>
        <el-input v-model="queryForm.title" size="small" clearable style="width: 200px!important;"
                  placeholder="资产名称">
          <el-button slot="append" size="small" icon="el-icon-search" @click="getList"></el-button>
        </el-input>
        <el-button type="text" @click="drawer=true" style="color: #999999;margin-left: 10px;">
          <i class="el-icon-s-operation"></i>&nbsp;高级搜索
        </el-button>
      </div>
    </el-row>
    <el-table :data="DataList" height="calc( 100% - 100px)" @selection-change="handleSelectionChange"
              style="width: 100%;"
              stripe border size="small">
      <el-table-column type="index" label="编号" width="50">
      </el-table-column>
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="title" label="资产名称(点击查看详情)" width="200">
        <template slot-scope="scope">
          <div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">{{ scope.row.title }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="typename" label="资产分类" width="120" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.typename2 }} {{ scope.row.typename1 }}
        </template>
      </el-table-column>
      <el-table-column prop="storename" label="所在位置" min-width="120" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.storename1 }} {{ scope.row.storename2 }} {{ scope.row.storename3 }}
        </template>
      </el-table-column>
      <el-table-column prop="pinpai" label="品牌" width="120"></el-table-column>
      <el-table-column prop="xinghao" label="型号" width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="jine" label="价格" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>￥{{ scope.row.jine }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="used_num" label="已用数量" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="store_num" label="剩余数量" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="yujing_num" label="预警数量" width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="last_yujing" label="预警时间" width="120" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.last_yujing ? scope.row.last_yujing.substr(0, 10) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template slot-scope="scope">
          <div class="flexEnd">
            <el-tag v-if="scope.row.store_num>0" size="mini" style="cursor: pointer;" @click="showPaifa(scope.row)">
              派发
            </el-tag>
            <el-tag size="mini" style="margin-left: 10px;cursor: pointer" @click="editItem(scope.row)"><i
                class="el-icon-edit"></i></el-tag>
            <el-tag type="danger" size="mini" style="margin-left: 10px;cursor: pointer;" @click="removeItem(scope.row)">
              <i class="el-icon-delete"></i></el-tag>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="cus_page"
                   @size-change="handleSizeChange"
                   @current-change="paginate"
                   :current-page.sync="page.current_page"
                   :page-sizes="[10, 50, 100]"
                   :page-size="page.pre_page"
                   layout="total,sizes, prev, pager, next"
                   :total="page.count">
    </el-pagination>
    <!--新增资产-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="1000px" custom-class="width_800 cus_dialog"
               :close-on-click-modal="false">
      <el-form :model="EditItem" ref="addzc" label-width="120px">
        <div class="titleBar flexStart">
          <div class="div flexStart">资产信息</div>
        </div>
        <el-row style="margin-top: 10px;">
          <el-col :span="12">
            <el-form-item label="资产名称:" prop="title">
              <el-input v-model="EditItem.title" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="资产分类:" prop="zc_typeid">
              <el-cascader ref="myCascader1" v-model="EditItem.zc_typeid" :options="typeList" size="mini"
                           :disabled="disabled"
                           :props="optionProps1" :show-all-levels="true" filterable placeholder="请选择"
                           @change="handleCascaderChange1"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计量单位:" prop="jldw">
              <el-input v-model="EditItem.jldw" size="mini" disabled placeholder="请选择分类"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所在位置:" prop="zc_storeid">
              <el-cascader ref="myCascader" v-model="EditItem.zc_storeid" :options="areaList" size="mini"
                           :disabled="disabled"
                           :props="optionProps" :show-all-levels="true" filterable placeholder="请选择"
                           @change="handleCascaderChange"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="购置方式:" prop="zc_gztype">
              <el-select v-model="EditItem.zc_gztype" size="mini" :disabled="disabled" placeholder="请选择">
                <el-option label="外购" value="外购"></el-option>
                <el-option label="租赁" value="租赁"></el-option>
                <el-option label="借用" value="借用"></el-option>
                <el-option label="自行建造" value="自行建造"></el-option>
                <el-option label="其他方式" value="其他方式"></el-option>
              </el-select>
              <!--							<el-input v-model="EditItem.zc_gztype" size="mini" :disabled="disabled" placeholder="资产购置方式，采购，租赁，借用等"></el-input>-->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="购置/借用日期:" prop="zc_date">
              <el-date-picker v-model="EditItem.zc_date" type="date" size="mini" :disabled="disabled"
                              value-format="yyyy-MM-dd"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="品牌:" prop="pinpai">
              <el-input v-model="EditItem.pinpai" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号:" prop="xinghao">
              <el-input v-model="EditItem.xinghao" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-for="(zd,idx) in EditItem.zdjson" :key="idx">
            <el-form-item :label="zd.name+':'">
              <el-input v-if="!zd.type||zd.type=='string'" size="small" v-model="zd.value" :disabled="disabled"
                        placeholder="请输入"></el-input>
              <el-input-number v-if="zd.type=='number'" v-model="zd.value" size="small" :disabled="disabled"
                               controls-position="right"
                               placeholder="请输入"></el-input-number>
              <el-date-picker v-if="zd.type=='date'" v-model="zd.value" style="width: 100%" type="date" size="small"
                              :disabled="disabled"
                              value-format="yyyy-MM-dd" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="价值金额:" prop="jine">
              <el-input v-model="EditItem.jine" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量:">
              <el-input-number v-model="EditItem.store_num" size="mini" :disabled="disabled" controls-position="right"
                               placeholder="请输入"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="资产描述说明:" prop="zcdesc">
              <el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.zcdesc" size="mini"
                        :disabled="disabled"
                        placeholder="资产描述说明"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="上传图片:" v-show="!disabled">
              <el-upload action="" :http-request="uploadOss" :on-success="uploadCover" :on-preview="uploadPreview"
                         :on-remove="handleRemove" :file-list="files" name="image">
                <el-button size="mini" plain icon="el-icon-upload2" type="primary" style="margin-left: 10px;">点击上传
                </el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="上传图片:" v-show="disabled&&files&&files.length>0">
              <!-- <a href="javascript:;" @click="uploadPreview(f)">{{f.filename}}</a> -->
              <div class="updimage" v-for="(f,idx) in files" :style="{backgroundImage:'url('+f.src+')'}">
                <img :src="f.src" onclick="viewImage(this)" style="opacity: 0;"/>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider content-position="left">
          <el-button type="text" size="small" @click="addCusField">字段不够?点此添加</el-button>
        </el-divider>
        <div class="titleBar flexStart">
          <div class="div flexStart">维保信息</div>
        </div>
        <el-row style="margin-top: 10px;">
          <el-col :span="12">
            <el-form-item label="维保供应商:" prop="wb_gxy">
              <el-input v-model="EditItem.wb_gxy" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="维保联系人:" prop="wb_lxr">
              <el-input v-model="EditItem.wb_lxr" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="维保电话:" prop="wb_phone">
              <el-input v-model="EditItem.wb_phone" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="维保到期时间:" prop="wb_deadline">
              <el-date-picker v-model="EditItem.wb_deadline" type="date" size="mini" :disabled="disabled"
                              value-format="yyyy-MM-dd"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="维保说明:" prop="wb_desc">
              <el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.wb_desc" size="mini"
                        :disabled="disabled"
                        placeholder="维保说明"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预警数量:">
              <el-input-number v-model="EditItem.yujing_num" size="mini" :disabled="disabled" controls-position="right"
                               placeholder="请输入"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="!disabled&&!EditItem.id">
          <div class="titleBar flexStart">
            <div class="div flexStart">添加派发信息</div>
          </div>
          <el-row style="margin-top: 10px" class="targetUsers flexStart">
            <el-col :span="6">
              <el-form-item label="派发数量:" label-width="80px">
                <el-input-number v-model="EditItem.cus_paifanum" size="mini" :disabled="disabled"
                                 controls-position="right" placeholder="输入派发数量"
                                 style="width: 100%"></el-input-number>
              </el-form-item>
            </el-col>
            <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showUser(2)"
                       style="margin-left: 10px;">选择领用人
            </el-button>
          </el-row>
          <div v-if="EditItem.target_users&&EditItem.target_users.length>0"
               v-for="(uitem,uidx) in EditItem.target_users" :key="uidx"
               style="margin-top: 10px;" class="targetUsers flexStartUp">
            <div style="display: inline-block;width: 20px;line-height: 40px;color: #409EFF">{{ uidx + 1 }}.</div>
            <el-row class="flex_1">
              <el-col :span="8">
                <el-form-item label="领用人:">
                  <el-input v-model="uitem.name" size="mini" disabled placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="派发数量:">
                  <el-input-number v-model="uitem.num" size="mini" :disabled="disabled"
                                   controls-position="right" placeholder="请输入"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="领用日期:">
                  <el-date-picker v-model="uitem.zc_date" type="date" size="mini"
                                  value-format="yyyy-MM-dd" placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="领用后位置:">
                  <el-cascader v-if="!disabled" ref="myCascader2" v-model="uitem.zc_whereid"
                               :options="areaList" size="mini"
                               :disabled="disabled"
                               :props="optionProps" :show-all-levels="true" filterable
                               placeholder="请选择"></el-cascader>
                  <el-input v-if="disabled" v-model="uitem.zc_where" size="mini" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="备注:">
                  <el-input v-model="uitem.remark" size="mini" :disabled="disabled"
                            placeholder="派发资产内容描述"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div style="margin-left: 20px;height: 40px;line-height: 40px;">
              <el-tag @click="del_target_users(uidx)" type="danger" size="mini" title="删除"><i
                  class="el-icon-remove"></i></el-tag>
            </div>
          </div>
        </div>
        <div v-if="disabled">
          <div class="titleBar flexStart">
            <div class="div flexStart">领用人列表</div>
          </div>
          <el-row style="margin-top: 20px;">
            <el-table :data="logList" size="small" height="50vh" border style="width: 100%">
              <el-table-column prop="cdate" label="操作时间" width="180">
              </el-table-column>
              <el-table-column prop="create_uid" label="操作人" width="180">
              </el-table-column>
              <el-table-column prop="dz_name" label="物品名称">
              </el-table-column>
              <el-table-column prop="target_name" label="领用人">
              </el-table-column>
              <el-table-column prop="dz_num" label="领用数量">
              </el-table-column>
              <el-table-column prop="zc_where" label="领用后位置">
              </el-table-column>

            </el-table>
          </el-row>
        </div>
      </el-form>
      <div slot="footer">
        <el-button v-if="!disabled" type="primary" @click="saveForm()">保存</el-button>

        <el-button v-if="disabled" type="primary" @click="dialogVisible=false">关闭</el-button>
      </div>
    </el-dialog>
    <!--        派发单-->
    <el-dialog title="派发单" :visible.sync="dialogVisible_distribute" width="1000px"
               custom-class="width_800 cus_dialog">
      <el-form :model="EditItem" ref="EditItem" label-width="100px">
        <el-row style="margin-top: 10px" class="targetUsers flexStart">
          <el-col :span="6">
            <el-form-item label="派发数量:" label-width="80px">
              <el-input-number v-model="EditItem.cus_paifanum" size="mini" :disabled="disabled"
                               controls-position="right" placeholder="输入派发数量"
                               style="width: 100%"></el-input-number>
            </el-form-item>
          </el-col>
          <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showUser(2)"
                     style="margin-left: 10px;">选择领用人
          </el-button>
        </el-row>
        <div v-if="EditItem.target_users&&EditItem.target_users.length>0"
             v-for="(uitem,uidx) in EditItem.target_users" :key="uidx"
             style="margin-top: 10px;" class="targetUsers flexStartUp">
          <div style="display: inline-block;width: 20px;line-height: 40px;color: #409EFF">{{ uidx + 1 }}.</div>
          <el-row class="flex_1">
            <el-col :span="8">
              <el-form-item label="领用人:">
                <el-input v-model="uitem.name" size="mini" disabled placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="派发数量:">
                <el-input-number v-model="uitem.num" size="mini" :disabled="disabled"
                                 controls-position="right" placeholder="请输入"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="领用日期:">
                <el-date-picker v-model="uitem.zc_date" type="date" size="mini"
                                value-format="yyyy-MM-dd" placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="领用后位置:">
                <el-cascader v-if="!disabled" ref="myCascader2" v-model="uitem.zc_whereid"
                             :options="areaList" size="mini"
                             :disabled="disabled"
                             :props="optionProps" :show-all-levels="true" filterable placeholder="请选择"></el-cascader>
                <el-input v-if="disabled" v-model="uitem.zc_where" size="mini" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="备注:">
                <el-input v-model="uitem.remark" size="mini" :disabled="disabled"
                          placeholder="派发资产内容描述"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="margin-left: 20px;height: 40px;line-height: 40px;">
            <el-tag @click="del_target_users(uidx)" type="danger" size="mini" title="删除"><i
                class="el-icon-remove"></i></el-tag>
          </div>
        </div>
        <el-divider style="width: 100%"></el-divider>

        <el-row style="margin-top: 20px;">
          <el-table :data="EditItem.listData" border style="width: 100%" size="small">
            <el-table-column label="序号" type="index" width="55"></el-table-column>
            <el-table-column prop="title" label="资产名称" min-width="120"></el-table-column>
            <el-table-column prop="typename" label="资产分类" width="120" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{ scope.row.typename1 }} {{ scope.row.typename1 }}
              </template>
            </el-table-column>
            <el-table-column prop="storename" label="所在位置" min-width="80" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{ scope.row.storename1 }} {{ scope.row.storename2 }} {{ scope.row.storename3 }}
              </template>
            </el-table-column>
            <el-table-column prop="pinpai" label="品牌"></el-table-column>
            <el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="store_num" label="剩余数量" :show-overflow-tooltip="true"></el-table-column>
          </el-table>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="saveDistribute">保存</el-button>
      </div>
    </el-dialog>
    <Deptuser v-if="showUserDialog" @getUser="getUser" @closeUser="closeUser"></Deptuser>

    <biaoqian v-if="showBiaoqian" :zclist="bqZcList"></biaoqian>
    <div style="display: none;">
      <el-upload action="" :multiple="true" :http-request="uploadOss" :on-success="uploadAttch" :show-file-list="false"
                 name="image">

        <el-button size="mini" id="addXlsbtn">+</el-button>
      </el-upload>
    </div>


    <el-dialog title="错误日志" :visible.sync="dialogErrorVisible" :close-on-click-modal="false">
      <div style="padding-top: 10px;" v-for="item in ErrLog">{{ item }}</div>
    </el-dialog>

    <!--       新增字段-->
    <el-dialog title="自定义字段" :visible.sync="showCusField" width="600px" custom-class="width_800 cus_dialog">


      <el-form :model="FieldItem" ref="FieldItem" label-width="120px">
        <el-row style="margin-top: 20px;">
          <el-form-item label="所属类型:">
            <el-input v-model="FieldItem.value" disabled></el-input>
          </el-form-item>

          <el-form-item label="设备名称:">
            <el-input v-model="FieldItem.cname" disabled></el-input>
          </el-form-item>
          <el-form-item label="英文编码:">
            <el-input v-model="FieldItem.ename" disabled></el-input>
          </el-form-item>
          <el-divider style="width: 100%"></el-divider>
          <div style="color: #409EFF;margin: 10px 0 10px 0;">自定义字段</div>
          <el-row v-for="(c,idx) in FieldItem.zdjson" class="flexStart drawer_box">
            <el-col :span="11">
              <el-form-item label="字段名称:" label-width="80px">
                <el-input size="small" v-model="c.name" placeholder="字段名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" style="margin-left: 10px;">
              <el-form-item label="字段类型" label-width="80px">
                <el-select size="small" v-model="c.type" placeholder="请选择">
                  <el-option value="string" label="文本"></el-option>
                  <el-option value="number" label="数值"></el-option>
                  <el-option value="date" label="日期"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-tag @click="delCus(c,idx)" type="danger" size="mini" style="margin-left: 20px;"><i
                class="el-icon-remove"></i></el-tag>
          </el-row>
          <el-row style="margin: 20px 0">
            <el-button @click="addCus" title="新增系定义属性" size="mini" type="primary" plain icon="el-icon-plus"
                       circle></el-button>
          </el-row>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="saveCusField">保存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Pagination from '../../com/pagination.vue'
import Deptuser from '../../com/deptuser.vue'
import biaoqian from '../zc/biaoqian.vue'

export default {
  components: {
    Pagination,
    Deptuser,
    biaoqian
  },
  data() {
    return {
      DataList: [],
      multipleSelection: [],
      queryForm: {},
      page: {
        current_page: 1,
        pre_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      dialogTitle: '新增资产',
      dialogVisible: false,
      EditItem: {target_users: []},
      disabled: false,
      typeList: [],
      areaList: [],
      optionProps: {value: 'id', label: 'storename', emitPath: false, checkStrictly: true},
      optionProps1: {value: 'id', label: 'cname', emitPath: false},
      files: [],
      logList: [],
      dialogVisible_borrow: false,
      userinfo: {},
      temp_list: [],//todo 未用备用
      showUserDialog: false,
      userDialogType: null,
      dialogVisible_return: false,
      dialogVisible_distribute: false,
      dialogVisible_withdraw: false,
      drawer: false,//高级搜索面板
      FieldItem: {},
      showCusField: false,
      curDate: '',
      printObj: {
        id: "#printDiv",
        popTitle: "",
        extraCss: "",
        extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
      },
      showPrinter: false,
      showBiaoqian: false,
      bqZcList: [],
      dialogErrorVisible: false,
      ErrLog: [],
    }
  },
  mounted() {
    this.getList();
    this.getType();
    this.getArea();
    this.getCurDate();
    let _this = this
    if (localStorage.getItem("user")) {
      _this.userinfo = JSON.parse(localStorage.getItem("user"))
      // console.log(_this.userinfo)
    }
  },
  methods: {
    onSearch() {
      this.page.current_page = 1
      this.getList()
    },
    onReset() {
      this.queryForm = {}
      this.page.current_page = 1
      this.getList()
    },
    handleCommand(command) {
      switch (command) {
        case 3:
          break;
        case 0:
          document.getElementById("addXlsbtn").click()
          break;
        default:
          break;
      }

    },
    dep_Arr(arr) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.store_num <= 0) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },

    uploadCover(e) {
      this.files.push({filename: e.fileName, src: e.src, name: e.fileName, url: e.src})
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.files = fileList
    },
    uploadPreview(file) {
      // console.log(879,file)
      window.open(file.src || file.url)
    },
    getType() {
      let _this = this
      this.$http.post("/api/zc_type", {type: 'dz'}).then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.typeList = result
        })
      })
    },
    getArea() {
      let _this = this
      this.$http.post("/api/zc_store").then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.areaList = result
        })
      })
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },
    getList() {
      let _this = this
      let params = {}, arr = []
      params.page = _this.page.current_page
      params.pagesize = _this.page.pre_page
      if (_this.queryForm != {}) {
        for (let key in _this.queryForm) {
          if (_this.queryForm[key]) {
            arr.push({key: key, value: _this.queryForm[key]})
          }
        }
        params.search = arr
      }
      this.$http.post("/api/zc_dz_list", params).then(res => {
        this.DataList = res.data.data;
        this.page = res.data.page
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },
    showDetails(row) {
      let _this = this
      this.editItem(row)
      this.dialogTitle = '资产详情'
      this.dialogVisible = true
      this.disabled = true
    },
    showPaifa(row) {
      let _this = this
      _this.EditItem = {id: row.id, target_users: [], listData: [{...row}]}
      _this.EditItem = {..._this.EditItem}
      this.dialogVisible_distribute = true
      this.disabled = false
    },
    // 派发单
    saveDistribute() {
      let _this = this
      if (_this.EditItem.target_users && _this.EditItem.target_users.length > 0) {
        let params = _this.EditItem
        _this.EditItem.target_users.map(t => {
          let allCates = _this.getFathersById(t.zc_whereid, _this.areaList, 'id', 'children', 'storename')
          t.zc_where = allCates.join('/')
          let arr = []
          t.files = JSON.stringify(arr)
        })
        _this.$http.post('/api/zc_dz_paifa', params).then(res => {
          _this.dialogVisible_distribute = false;
          _this.$message.success('保存成功');
          _this.getList()
        })
      } else {
        _this.$message.warning('请选择领用人')
        return;
      }
    },
    add() {
      let _this = this
      this.dialogTitle = '新增资产'
      this.dialogVisible = true
      this.disabled = false
      this.EditItem = {
        zc_date: _this.curDate,
        target_users: []
      }
      this.files = []
      this.EditItem = {..._this.EditItem}
      this.files = [..._this.files]
    },
    editItem(row) {
      let _this = this
      this.dialogTitle = '编辑资产'
      this.dialogVisible = true
      this.disabled = false
      _this.EditItem = row
      let arr = [], cus_arr = []
      this.$http.post("/api/zc_dz_one", {id: row.id}).then(res => {
        let rData = res.data
        if (rData.logs && rData.logs.length > 0) {
          rData.logs.map(l => {
            l.union_id = l.target_uid
            l.name = l.target_name
            l.num = l.dz_num
          })
          _this.EditItem.target_users = rData.logs
        }
        _this.logList = rData.logs;
        if (rData.files != '' && rData.files != null && rData.files != undefined) {
          arr = JSON.parse(rData.files)
          _this.files = arr
        }
        if (row.remark && row.remark != '') {
          _this.EditItem.zdjson = JSON.parse(row.remark)
        }
        _this.EditItem = {..._this.EditItem}
        console.log(_this.EditItem)
      })
    },
    saveForm() {
      let _this = this
      let params = {}
      params = {..._this.EditItem}
      if (!params.title) {
        _this.$message.warning('请填写资产名称！')
        return
      }
      if (!params.zc_typeid) {
        _this.$message.warning('请选择资产类型！')
        return
      }
      if (!params.zc_storeid) {
        _this.$message.warning('请选择所在仓库！')
        return
      }
      if (!params.store_num) {
        _this.$message.warning('请填写物品数量！')
        return
      }
      if (this.files.length > 0) {
        params.files = JSON.stringify(_this.files)
      }
      params.remark = JSON.stringify(params.zdjson)
      if (params.target_users && params.target_users.length > 0) {
        params.target_users.map(t => {
          let allCates = _this.getFathersById(t.zc_whereid, _this.areaList, 'id', 'children', 'storename')
          t.zc_where = allCates.join('/')
          let arr = []
          t.files = JSON.stringify(arr)
        })
      }
      this.$http.post('/api/zc_dz_save', params).then(res => {
        _this.dialogVisible = false;
        this.$message({
          type: 'success',
          message: '保存成功'
        });
        _this.getList()
      })
    },
    removeItem(item) {
      let _this = this
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/zc_dz_delete", {
          id: item.id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          });

          _this.getList()
        })

      }).catch(() => {

      });
    },
    delSlct() {
      let _this = this
      if (_this.multipleSelection.length == 0) {
        _this.$message.warning('请选择至少一条资产')
      } else {
        _this.$confirm('此操作将永久删除所选数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.$http.post("/api/zc_dz_delete", {
            id: _this.multipleSelection.map(item => {
              return item.id
            }).join(',')
          }).then(res => {
            _this.$message.success('删除成功');
            _this.getList()
          })
        }).catch(() => {

        });
      }
    },
    paginate(val) {
      this.page.current_page = val
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.pre_page = val
      this.getList();
    },
    handleCascaderChange(e) {
      console.log(e)
    },
    handleCascaderChange1(e) {
      if (e) {
        let _this = this
        _this.EditItem.zc_typeid = e
        let a = _this.$refs['myCascader1'].getCheckedNodes()[0].data
        // console.log(1114, a)
        _this.FieldItem = a
        _this.EditItem.ename = a.ename
        _this.EditItem.jldw = a.unit
        if (a.zdjson && a.zdjson != '') {
          _this.EditItem.zdjson = JSON.parse(a.zdjson)
        }
        _this.EditItem = {..._this.EditItem}
      }
    },
    handleCascaderChange2(e) {
      // console.log(e)
      if (e) {
        let _this = this
        _this.EditItem.zc_whereid = e
        let a = _this.$refs['myCascader2'].getCheckedNodes()[0].data
        let allCates = _this.getFathersById(e, _this.areaList, 'id', 'children', 'storename')
        allCates.pop();
        let ss = allCates.length > 0 ? `${allCates.join('/')}/${a.storename}` : a.storename
        _this.EditItem.zc_where = ss
        console.log(_this.EditItem.zc_where)
        _this.EditItem = {..._this.EditItem}
      }
    },
    /**
     * 查找匹配的完整路径(arrRes:id集合;arrText:中文集合;)
     * id:     匹配的值
     * data: 匹配的数组数据
     * prop: 匹配的字段名
     * children: 子集label
     * cname: 输出数组需要的字段名
     */
    getFathersById(id, data, prop, children, cname) {
      var arrRes = [], arrText = []
      const rev = (data, nodeId) => {
        for (var i = 0, length = data.length; i < length; i++) {
          const node = data[i]
          if (node[prop] == nodeId) {
            arrRes.unshift(node[prop])
            arrText.unshift(node[cname])
            return true
          } else {
            if (node[children] && node[children].length) {
              if (rev(node[children], nodeId)) {
                arrRes.unshift(node[prop])
                arrText.unshift(node[cname])
                return true
              }
            }
          }
        }
        return false
      }
      rev(data, id)
      return arrText  //返回中文集合
    },

    showUser(type) {
      this.userDialogType = type
      this.showUserDialog = true
    },
    del_target_users(index) {
      let _this = this
      this.EditItem.target_users.splice(index, 1)
      this.EditItem = {..._this.EditItem}
    },
    getUser(e) {
      console.log(e)
      let _this = this
      let arr = []
      if (e && e.length > 0) {
        if (_this.userDialogType == 1) {
          _this.EditItem.target_uid = e[0].union_id
          _this.EditItem.borrower_name = e[0].name
          _this.EditItem = {..._this.EditItem}
        } else if (_this.userDialogType == 2) {
          e.map((item, idx) => {
            arr.push({
              union_id: item.union_id,
              name: item.name,
              num: _this.EditItem.cus_paifanum ? _this.EditItem.cus_paifanum : 1,
              zc_date: _this.curDate,
            })
            if (_this.EditItem.target_users && _this.EditItem.target_users.length > 0) {
              _this.EditItem.target_users.map(orgnl => {
                if (orgnl.union_id == item.union_id) {
                  arr.splice(idx, 1)
                }
              })
            }
          })
          _this.EditItem.target_users = _this.EditItem.target_users.concat(arr)
          console.log(arr, _this.EditItem)
          _this.EditItem = {..._this.EditItem}
        }
      }
      _this.showUserDialog = false
    },
    closeUser() {
      this.showUserDialog = false
    },
    addCusField() {
      let _this = this
      if (!this.FieldItem.id) {
        this.$message.warning('请先选择耗材分类！')
        return;
      }
      this.showCusField = true
      console.log(1103, _this.FieldItem.zdjson)
      if (_this.FieldItem.zdjson && _this.FieldItem.zdjson != '' && _this.FieldItem.zdjson != 'null' && _this.FieldItem.zdjson != 'undefined') {
        _this.FieldItem.zdjson = JSON.parse(_this.FieldItem.zdjson)
      } else {
        _this.FieldItem.zdjson = []
      }
      _this.FieldItem = {..._this.FieldItem}
    },
    saveCusField() {
      let _this = this
      let objvalue = _this.EditItem.zdjson    //保存后回显防止旧值被覆盖
      let params = {}, obj = {}
      obj = JSON.parse(JSON.stringify(_this.FieldItem))
      params = {...obj}
      params.remark = ""
      params.zdjson = JSON.stringify(obj.zdjson)
      this.$http.post("/api/zc_type_save", params).then(res => {
        _this.showCusField = false
        _this.$message({
          type: 'success',
          message: '保存成功'
        });
        _this.getType()
        _this.EditItem.zdjson = JSON.parse(params.zdjson)
        for (let a in objvalue) {
          for (let b in _this.EditItem.zdjson) {
            if (a == b && objvalue[a] != '') {
              _this.EditItem.zdjson[b] = objvalue[a]
            }
          }
        }
        _this.EditItem = {..._this.EditItem}
      })
    },
    addCus() {
      let _this = this
      this.FieldItem.zdjson.push({})
      this.FieldItem = {..._this.FieldItem}
    },
    delCus(item, index) {
      let _this = this
      this.FieldItem.zdjson.splice(index, 1)
      this.FieldItem = {..._this.FieldItem}
    },
    print_ruku() {
      this.$alert("敬请期待")
      return;
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请选择一条资产');
        return
      } else {
        this.showPrinter = true
      }
    },
    getCurDate() {
      let d = new Date();
      let year = d.getFullYear(); //获取完整的年份(4位)
      let month = d.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let date = d.getDate(); //获取当前日(1-31)
      d.getDay(); //获取当前星期X(0-6,0代表星期天)
      d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
      d.getHours(); //获取当前小时数(0-23)
      d.getMinutes(); //获取当前分钟数(0-59)
      d.getSeconds(); //获取当前秒数(0-59)
      d.getMilliseconds(); //获取当前毫秒数(0-999)
      d.toLocaleDateString(); //获取当前日期
      let mytime = d.toLocaleTimeString(); //获取当前时间
      d.toLocaleString(); //获取日期与时间
      this.curDate = `${year}-${month}-${date}`
    },
    uploadAttch(e) {
      console.log(e)
      this.$http.post("/api/zc_dz_import", {url: e.src}).then(res => {
        this.$message.success('导入成功')
        if (res.data.errors.length > 0) {
          this.ErrLog = res.data.errors
          this.dialogErrorVisible = true;
        }
        this.getList();
      })
    },
    uploadXls() {
      document.getElementById('addXlsbtn').click()
    },
  }
}
</script>

<style lang="less" type="text/scss">


.pagezclist {
  .labelCss {
    .el-form-item__label {
      color: #909399 !important;
      font-size: 12px;
    }
  }

  .el-input, .el-select, .el-cascader {
    width: 100% !important;
  }

  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF !important;
      background-color: #Ecf5ff !important;
      border-color: #b3d8ff !important;
    }
  }

  .el-divider {
    margin-top: 0;
  }

  .el-drawer__wrapper {
    top: 70px;
  }

  .drawer_box, .targetUsers {
    padding: 10px;

    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .updimage {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
    border: 1px dotted #ccc;
    background-size: 100% auto;
    background-position: center;
    overflow: hidden;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .cus_page {
    margin-top: 10px;

    .el-pagination__sizes {
      width: 130px;
    }
  }
}

</style>
